<template>
	<v-container class="main-container">
		<backTo
			elClass="mb-10 d-block"
			:link="`/admin/quiz-editor/${$route.params.id}`"
			><template #text>Back</template></backTo
		>
		<AdminDefaultSubheader>{{ quiz.name }}</AdminDefaultSubheader>
		<AdminDefaultHeader class="mt-5"
			>Assign a course to this quiz</AdminDefaultHeader
		>
		<section>
			<AdminDefaultSubheader class="mb-5"
				>Courses assigned to this quiz
			</AdminDefaultSubheader>
			<v-chip
				v-if="assignedCourseTitle"
				large
				close
				close-icon="mdi-delete"
				:loading="isLoading"
				@click:close="removeAssign"
			>
				<span class="mr-2">{{ assignedCourseTitle }}</span>
			</v-chip>
		</section>
		<article class="mt-15">
			<v-autocomplete
				v-model="courseAssignedId"
				:items="allCourses"
				:item-text="'title'"
				:item-value="'id'"
				:loading="isLoading"
				outlined
				class="mt-10"
				label="Search for Courses"
			></v-autocomplete>
		</article>
		<form-message v-if="message" :type="status">{{ message }}</form-message>
		<v-btn
			class="admin-primary-button primary-contrast-background"
			@click="assignQuiz"
			>Assign</v-btn
		>
	</v-container>
</template>

<script>
import AdminDefaultSubheader from "@/components/AdminDefaultSubheader.vue";
import AdminDefaultHeader from "@/components/AdminDefaultHeader.vue";
import BackTo from "@/components/BackTo.vue";
import { mapGetters } from "vuex";
import { get } from "@/util/requests/get";
import { post } from "@/util/requests/post";
import FormMessage from "@/components/FormMessage.vue";
import { remove } from "@/util/requests/remove";
import { put } from "@/util/requests/put";

export default {
	name: "QuizAssign",
	components: {
		AdminDefaultSubheader,
		AdminDefaultHeader,
		BackTo,
		FormMessage
	},
	computed: {
		...mapGetters(["allCourses", "isLoading", "allQuizzes"]),
		course() {
			return this.allCourses.find(el => el.id === this.courseAssignedId);
		},
		quizId() {
			return Number(this.$route.params.id);
		},
		assignedCourseTitle() {
			if (
				!!this.quiz?.courses === false ||
				!!this.quiz?.courses[0]?.course === false
			)
				return;
			return this.quiz?.courses[0].course.title;
		}
	},
	data() {
		return {
			rules: {
				required: value => !!value || "Required."
			},
			status: "",
			message: "",
			quiz: {},
			chapters: [],
			quizAssignedId: "",
			courseAssignedId: "",
			selectedChapterId: ""
		};
	},

	methods: {
		async getQuiz() {
			await this.$store.dispatch("setIsLoading", true);
			const response = await get(`/quiz/${this.$route.params.id}`);
			const {
				data: { data }
			} = response;
			this.quiz = data;
			await this.$store.dispatch("setIsLoading", false);
		},

		async assignQuiz() {
			await this.$store.dispatch("setIsLoading", true);
			this.message = "";
			this.status = "";
			const payload = {
				course_id: this.courseAssignedId,
				quiz_id: this.quizId
			};
			try {
				if (this.assignedCourseTitle) {
					const response = await put(
						"/course_quiz",
						this.quiz.courses.id,
						payload
					);
				} else {
					const response = await post("/course_quiz", payload);
				}

				this.message = "Success";
				this.status = "success";
				await this.getQuiz();
			} catch (error) {
				console.error(error);
				this.message = error;
				this.status = "error";
			}

			await this.$store.dispatch("setIsLoading", false);
		},
		async removeAssign() {
			await this.$store.dispatch("setIsLoading", true);
			this.message = "";
			this.status = "";
			try {
				await remove(`/course_quiz/${this.quiz.courses[0].id}`);
				await this.getQuiz();
				this.message = "Success";
				this.status = "success";
				await this.$store.dispatch("setIsLoading", false);
			} catch (error) {
				console.error(error);
				this.message = error;
			}
		}
	},
	async mounted() {
		await this.getQuiz();
	}
};
</script>

<style></style>
